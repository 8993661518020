.fullpage-block {
  // type
  &.section, &.slide, &.content {
    position: relative;
    width: 100%;
  }
  &.content {
    height: 100%;
  }
  &.popup {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: none;
    &.open {
      display: block;
      z-index: 1050;
    }
    .btn-close-popup {
      position: absolute;
      top: 0;
      right: 0;
      color: rgba(255, 255, 255, 0.8);
      padding: 1rem;
      @include media-breakpoint-up(md) {
        padding: 3rem;
      }
    }
  }
  // content
  .content {
    position: relative;
    z-index: 1000;
    word-wrap: break-word;
    h2 {
      font-size: 1.5rem;
      font-weight: 300;
      line-height: 1.2;
      padding-bottom: 0.25rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        margin-bottom: 0;
      }
      @include media-breakpoint-up(xl) {
        font-size: 3.5rem;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .alignfull {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .image {
    position: absolute;
    top: 0;
    height: 100%;
  }
  // align
  &.align-, &.align-full .align- {
    &wide, &center, &left, &right {
      .content {
        padding: 1.5rem 2.5rem;
        max-height: 100%;
        @include media-breakpoint-up(md) {
          padding: 3rem;
        }
      }
    }
  }
  &.align- {
    &full {
      .content {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
      }
    }
    &wide, {
      .content {
        top: 0;
        left: 0;
        width: 100%;
        height: unset;
      }
    }
    &center {
      .content {
        width: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &left {
      .content {
        width: 100%;
        height: unset;
        @include media-breakpoint-up(md) {
          width: 50%;
          height: auto;
        }
      }
    }
    &right {
      .content {
        width: 100%;
        margin-top: 50%;
        height: unset;
        @include media-breakpoint-down(sm) {
          margin-top: 50vh !important;
          bottom: 0;
          display: flex;
          height: 50vh;
          justify-content: center;
          align-items: center;
        }
        @include media-breakpoint-up(md) {
          margin-left: 50%;
          width: 50%;
          height: auto;
        }
      }
      &.content, &.popup {
        > .content {
          margin-top: unset;
          height: unset;
          @include media-breakpoint-down(sm) {
            margin-top: unset !important;
          }
        }
      }
    }
  }
  &.content- {
    &top .fp-tableCell {
      vertical-align: top;
    }
    &center .fp-tableCell {
      vertical-align: middle;
    }
    &center.content > .content{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &bottom .fp-tableCell {
      vertical-align: bottom;
      bottom: 0;
    }
  }
  // layout
  &.layout-full, &.layout-wrapper .layout-full {
    //.content {
    //  position: relative;
    //  z-index: 1000;
    //  left: 0;
    //  width: 100%;
    //  padding: 1rem;
    //  @include media-breakpoint-up(md) {
    //    padding: 3rem;
    //  }
    //}
    .image {
      width: 100%;
    }
  }
  &.layout-image-left, &.layout-wrapper .layout-image-left {
    //.fp-tableCell {
    //  vertical-align: bottom;
    //}
    //&.align-center {
    //  .fp-tableCell {
    //    vertical-align: bottom;
    //    > .content {
    //
    //    }
    //  }
    //}
    //.content {
    //  position: relative;
    //  z-index: 1000;
    //  right: 0;
    //  width: 100%;
    //  height: 50%;
    //  padding: 1rem;
    //  @include media-breakpoint-up(md) {
    //    padding: 3rem;
    //    margin-left: 50%;
    //    width: 50%;
    //    height: auto;
    //  }
    //  @include media-breakpoint-down(md) {
    //    margin-top: 50%;
    //  }
    //}
    //&.content {
    //  &-top .fp-tableCell {
    //    vertical-align: top;
    //  }
    //  &-center .fp-tableCell {
    //    vertical-align: middle;
    //  }
    //  &-bottom .fp-tableCell {
    //    vertical-align: bottom;
    //  }
    //}
    .image {
      left: 0;
      width: 100%;
      height: 50%;
      @include media-breakpoint-up(md) {
        width: 50%;
        height: 100%;
      }
    }
  }
  &.layout-image-right, &.layout-wrapper .layout-image-right {
    //.content {
    //  position: relative;
    //  z-index: 1000;
    //  left: 0;
    //}
    .image {
      right: 0;
      width: 100%;
      height: 50%;
      bottom: 0;
      top: unset;
      @include media-breakpoint-up(md) {
        width: 50%;
        height: 100%;
      }
    }
  }
  //&.layout-wrapper {
  //  .content {
  //    width: 100%;
  //    padding: 0;
  //  }
  //  .image {
  //    width: 100%;
  //  }
  //}
}
.fullpage-navigation {
  position: absolute;
  z-index: 1100;
  list-style: none;
  padding: 1rem;
  margin-bottom: 0;
  @include media-breakpoint-up(md) {
    padding: 3rem;
  }
  &.left {
    left: 0;
  }
  &.top {
    top: 0;
  }
  &.bottom {
    bottom: 0;
  }
  &.right {
    right: 0;
  }
  &.list-group {
    .list-group-item {
      padding: 0.5rem 1rem;
    }
  }
  .popup-toggler {
    font-size: 1.25rem;
    line-height: 1.25;
    @include media-breakpoint-up(md) {
      font-size: 1.75rem;
    }
  }
}

// Editor styles
.block-editor-block-list__block[data-type="acf/fullpage-block"] {
  max-width: 100%;
  margin: 0;
  .wp-block[data-align="wide"], .wp-block[data-align="center"], .wp-block[data-align="left"], .wp-block[data-align="right"] {
    margin-right: 0;
    max-width: 100%;
    & > .acf-block-component {
      width: 100%;
    }
  }
}
.editor-fullpage-block-wrapper {
  // editor elements
  border: solid 1px #c6c6c6;
  padding: 0.35rem;
  &.section, &.slide {
    position: relative;
    background-color: #ececec;
  }
  .editor-info {
    width: 100%;
    background-color: #ffffff;
    text-align: left;
    padding: 0.35rem 0.75rem;
    z-index: 10;
    position: relative;
  }

  // block
  .fullpage-block {
    display: table;
    width: 100%;
    .fp-tableCell {
      display: table-cell;
    }
    &.section, &.slide, &.content, &.popup {
      position: relative;
      min-height: 726px;
      .content {
        z-index: 10;
        .block-editor-block-list__layout .wp-block {
          max-width: 100%;
        }
      }
    }
    //&.layout-full, &.layout-wrapper .layout-full {
    //  .content {
    //
    //  }
    //}
  }
}

/**
 * External
 */

@import '~bootstrap/scss/bootstrap';

.acf-field-group.no-style {
  padding: 0 !important;
  margin: 0 !important;
}
.acf-field-group.no-style > .acf-label {
  margin-left: 1rem;
}
.acf-field-group.no-style > .acf-input > .acf-fields.-border {
  border: 0 !important;
}

.iubenda-cs-preferences-link {
  display: none !important;
}

/**
 * Variables
 */

$theme-colors: (
  "primary": #575656,
  "secondary": #b3b2b2,
  "blue": #2b4fb8,
  "indigo": #6610f2,
  "purple": #6f42c1,
  "pink": #e83e8c,
  "red": #dc3545,
  "orange": #fd7e14,
  "yellow": #ffc107,
  "green": #28a745,
  "teal": #20c997,
  "cyan": #17a2b8,
  "white": #fff,
  "gray": #6c757d,
  "gray-dark": #343a40
);

$colors: (
  "primary": #575656,
  "secondary": #b3b2b2,
  "blue": #2b4fb8,
  "indigo": #6610f2,
  "purple": #6f42c1,
  "pink": #e83e8c,
  "red": #dc3545,
  "orange": #fd7e14,
  "yellow": #ffc107,
  "green": #28a745,
  "teal": #20c997,
  "cyan": #17a2b8,
  "white": #fff,
  "gray": #6c757d,
  "gray-dark": #343a40
);
